<template>
  <div class="mian">
    <navBar></navBar>
    <div class="conten">
      <div class="title">{{$t('login.register')}}</div>
      <div class="tabs">
        <div
          :style="tabsFlag ? 'background-color: white;' : 'color:#8796A5;'"
          @click="change"
        >
          {{$t('login.phone')}}
        </div>
        <div
          :style="tabsFlag ? 'color:#8796A5;' : 'background-color: white;'"
          @click="change"
        >
          {{$t('login.email')}}
        </div>
      </div>
      <!-- <div class="formBox">
        <div class="inputName">国家/地区</div>
        <div class="inputBox">
          <input placeholder="中国" />
          <van-icon name="arrow-down" />
        </div>
      </div> -->
      <div class="formBox" v-show="tabsFlag">
        <!-- <div class="inputName">{{$t('login.phone')}}</div> -->
        <!-- <div class="inputName">手机</div> -->
        <div style="display: flex;justify-content: space-between;">
          <!-- <div class="inputBox" style="width: 25%;">
            <input value="+1234" />
          </div> -->
          <div class="inputBox" style="flex: 1;">
            <input
              v-model="form.username"
              type="number"
              :placeholder="$t('register.enterphone')"
            />
          </div>
        </div>
      </div>
      <div class="formBox" v-show="!tabsFlag">
        <!-- <div class="inputName">{{$t('login.email')}}</div> -->
        <div class="inputBox">
          <input
            v-model="form.username"
            type="email"
            :placeholder="$t('register.enteremail')"
          />
        </div>
      </div>
      <div class="formBox" style="margin-top: 0;"  v-show="!tabsFlag">
        <div class="inputBox">
          <!-- <input placeholder="请输入验证码" /> -->
          <!-- <span class="van-icon" style="color: #325de3;right: -30px;"
            >获取验证码</span
          > -->
                <van-field
                  class="smsbtn"
                  :placeholder="$t('forget.entercode')"
                  v-model="form.sms"
                >
                  <template #button>
                    <van-button
                      v-if="sendemailbtn"
                      size="small"
                      type="primary"
                      @click.prevent="sendemail"
                      >{{ $t('forget.sendcode') }}</van-button
                    >
                    <van-button size="small" disabled v-else
                      >{{ sendemailtime }}s</van-button
                    >
                  </template>
                </van-field>
        </div>
      </div>
      <div class="formBox">
        <div class="inputBox">
          <input
            v-model="form.password"
            :type="flag ? 'text' : 'password'"
            :placeholder="$t('register.enterpassword')"
          />
          <van-icon name="eye-o" v-show="flag" @click="flag = !flag" />
          <van-icon name="closed-eye" v-show="!flag" @click="flag = !flag" />
        </div>
      </div>
      <div class="formBox">
        <div class="inputBox">
          <input
            v-model="form.repassword"
            :type="flag ? 'text' : 'password'"
            :placeholder="$t('register.enterpassword')"
          />
          <van-icon name="eye-o" v-show="flag" @click="flag = !flag" />
          <van-icon name="closed-eye" v-show="!flag" @click="flag = !flag" />
        </div>
      </div>
      <div class="formBox">
        <div class="inputBox">
          <input
            v-model="form.code"
            type="text"
            :placeholder="$t('register.invitecode')"
          />
        </div>
      </div>
      <div class="button" @click="register">{{ $t('register.quickreg') }}</div>
      <div style="display: flex;justify-content: center;margin-top: 30px;">
        <div style="color: #8796A5;">{{$t('login.hasaccount')}}</div>
        <div style="color: #325de3;margin-left: 20px;" @click="login">
          {{$t('login.login')}}
        </div>
      </div>
    </div>
    <div class="footer">
      <div style="color: #8796A5;">{{ $t('register.consent') }}</div>
      <div style="color: #325de3;margin-left: 20px;" @click="agreement">
        《{{ $t('register.agreement') }}》
      </div>
    </div>
  </div>
</template>
<script>
import navBar from '@/components/nav/nav.vue'

export default {
  components: {
    navBar
  },
  data() {
    return {
      checked: true,
      tabsFlag: true,
      flag: false,
      form: {
        username: '',
        password: '',
        repassword: '',
        sms: '',
        code: '',
        type: 0,
      },
      sendsmsbtn: true,
      sendsmstime: 60,
      sendemailbtn: true,
      sendemailtime: 60
    }
  },
  methods: {
    change(){
      this.tabsFlag = !this.tabsFlag;
      this.form.type = this.tabsFlag?0:1;
      console.log(this.way);
    },
    // 服务条款
    agreement() {
      this.$router.push({ path: '/terms' })
    },
    login() {
      this.$router.push({ path: '/login' })
    },
    register() {
      let obj = this.form
      console.log(obj)
        // eslint-disable-next-line no-prototype-builtins
          // 过滤掉原型链上的属性
          if (obj.username === '') {
            this.$toast.fail(this.$t('register.emailandphone'))
            return
          } else if (obj.password === '') {
            this.$toast.fail(this.$t('register.enterpassword'))
            return
          } else if (obj.code === '') {
            this.$toast.fail(this.$t('register.invitecode'))
            return
          } else if (obj.type === 1 && obj.sms === '') {
            this.$toast.fail(this.$t('forget.entercode'))
            return
          } else if (obj.repassword !== obj.password) {
              this.$toast.fail(this.$t('register.eqpass'))
              obj.repassword = ''
              return
          }

      this.getRigster()
    },
    async getRigster() {
      const { data } = await this.$http.post('/home/index/register', this.form)
      if (data) {
        if (data.code === 200) {
          this.$toast.success(
            this.getlang(data.msg, localStorage.getItem('lang'))
          )
          this.$router.push('/login')
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    },
    async sendemail() {
      if (!this.form.username) {
        this.$toast.fail(this.$t('forget.enteremail'))
        return false
      }
      if (
        !/^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
          this.form.username
        )
      ) {
        this.$toast.fail(this.$t('forget.emailformat'))
        return false
      }
      if (this.sendemailbtn === false) {
        return false
      }
      const { data } = await this.$http.post('/home/index/sendemail', {
        email: this.form.username
      })
      if (data) {
        if (data.code === 200) {
          this.sendemailbtn = false
          this.sendemailtime = 60
          this.$toast.success(this.$t('forget.codesent'))
          const eaminTimer = setInterval(() => {
            this.sendemailtime--
            if (this.sendemailtime <= 0) {
              this.sendemailbtn = true
              clearInterval(eaminTimer)
            }
          }, 1000)
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.mian {
  background-color: #eff1f3;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .conten {
    border-radius: 10px 10px 0 0;
    background-color: white;
    padding: 20px;
    flex: 1;
    .title {
      text-align: left;
      width: 100%;
      font-size: 26px;
      font-weight: 700;
      padding: 20px 0;
    }
    .tabs {
      margin-top: 20px;
      background-color: #eff1f3;
      display: flex;
      padding: 1px;
      border-radius: 5px;
      div {
        padding: 6px 0;
        border-radius: 4px;
        width: 50%;
      }
    }
    .formBox {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 20px;
      .inputName {
        font-size: 14px;
        width: 100%;
        text-align: left;
      }
      .inputBox {
        border: 1px solid #f7f7f7;
        border-radius: 3px;
        text-align: left;
        position: relative;
        .van-icon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        input {
          padding: 12px;
          border: none;
          outline: none;
          background: transparent;
        }
      }
    }
    .button {
      background-color: #325de3;
      text-align: center;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      color: white;
      margin-top: 20px;
      border: 1px solid #325de3;
      font-size: 20px;
    }
    .NoPassword {
      color: #325de3;
      margin-top: 20px;
      width: 100%;
      text-align: right;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    background-color: white;
    align-items: center;
    height: 140px;
  }
}
</style>
