<template>
  <div class="hread">
    <div>
      <van-icon size="24px" :name="leftIcon" @click="back()" />
    </div>
    <div>{{ middle }}</div>
    <div style="color: #325de3;" @click="triggerEvent">{{ rightText }}</div>
  </div>
</template>

<script>
export default {
  name: 'nav',
  props: {
    leftIcon: {
      type: String,
      default: 'arrow-left'
    },
    middle: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    triggerEvent() {
      this.$emit('handleRegister') // 触发自定义事件，并传递参数
    },
    back() {
      this.$router.back()
    }
  }
}
</script>
<style lang="less" scoped>
.hread {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
